import React, {useEffect, useState} from "react";
import {CompanyInterface} from "../../../helpers/types";
import {ReactComponent as EmptyX} from "../../../assets/svg/EmptyX.svg";
import Layout from "../../../components/Layout";
import SearchBar from "../../../modules/Dashboard/Settings/Components/Searchbar/TeamMemebers/SearchBar";
import SelectBusinessSkeleton from "./Skeleton/SelectBusinessSkeleton";
import getAvatarColorClass from "../../../helpers/avatarColors";
import useLogout from "../../../redux/init/services/hooks/useLogout";

interface SelectBusinessModelProps {
	accountCompaniesData?: CompanyInterface[] | null;
	selectCompanyFunc?: ((id: string) => void) | null;
}

function SelectBusinessModal({accountCompaniesData = null, selectCompanyFunc = null}: SelectBusinessModelProps): JSX.Element {
	const [filteredCompanies, setFilteredCompanies] = useState<CompanyInterface[]>(accountCompaniesData || []);

	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		if (!accountCompaniesData) return;
		if (searchTerm !== "") {
			const filteredData = accountCompaniesData.filter((item) => {
				return Object.values(item.name).join("").toLowerCase().includes(searchTerm.toLowerCase());
			});
			setFilteredCompanies(filteredData);
		} else {
			setFilteredCompanies(accountCompaniesData);
		}
	}, [searchTerm, accountCompaniesData]);

	const searchQuery = (searchValue: string) => {
		setSearchTerm(searchValue);
	};

	const {handleLogout} = useLogout();

	return (
		<>
			<div className="fixed z-100 flex h-full w-full items-center justify-center bg-white ">
				<Layout showNothing>
					<div className="flex w-full max-w-sm flex-col items-center justify-start space-y-4 overflow-hidden rounded-lg py-8 3xs:shadow">
						<div className="text-2xl font-medium text-black">Select a Business</div>
						<div className="w-full px-4 pt-2">
							{accountCompaniesData && accountCompaniesData.length > 5 && (
								<SearchBar placeholder="Search business name" value={searchTerm || ""} onChange={(e: string) => searchQuery(e)} />
							)}
						</div>

						{accountCompaniesData ? (
							<>
								{filteredCompanies.length > 0 && (
									<div className="w-full">
										<div className={"max-h-76 overflow-hidden transition-all duration-300 ease-in-out"}>
											<div className="flex w-full flex-col items-start justify-start">
												<div className="select__business flex h-full max-h-76 w-full flex-col overflow-y-auto">
													{filteredCompanies.map((item, index) => (
														<div
															className="flex w-full cursor-pointer flex-row items-center justify-start space-x-4 px-6 py-6 hover:bg-blue-senary"
															onClick={() => (selectCompanyFunc ? selectCompanyFunc(item.id) : null)}
															key={index}
														>
															<div>
																<div
																	className={`flex h-8 w-8 items-center justify-center rounded-full text-black-secondary ${getAvatarColorClass(
																		item.id
																	)}`}
																>
																	{item.name.trim().substring(0, 1)}
																</div>
															</div>
															<div className="flex flex-col items-start justify-start">
																<div className="text-base text-black">{item.name}</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								)}
								{filteredCompanies.length < 1 && (
									<div className="flex flex-col items-center justify-center space-y-2 text-center py-12">
										<EmptyX className="h-16 w-16" />
										<span className="text-sm text-black-secondary">No result</span>
									</div>
								)}
							</>
						) : (
							<>
								<div className="space-y-4 px-8 w-full">
									<SelectBusinessSkeleton />
									<SelectBusinessSkeleton />
								</div>
							</>
						)}
					</div>
					<>
						<p className="flex flex-row  space-x-2 pt-6 text-base font-normal text-black-tertiary">
							<span className=" cursor-pointer whitespace-nowrap text-blue" onClick={() => handleLogout()}>
								Log Out
							</span>
						</p>
					</>
				</Layout>
			</div>
		</>
	);
}

export default SelectBusinessModal;
