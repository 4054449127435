import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {Link, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import AcceptPayments from "../assets/svg/Landing/AcceptPayments.png";
import BillPaymentNg from "../assets/svg/Landing/bill-payment.png";
import BusinessCurrentAccountNg from "../assets/svg/Landing/business-current-account.png";
import BusinessTillAccount from "../assets/svg/Landing/BusinessTillAccount.png";
import DropdownHead from "./General/Dropdown/DropdownComponents/DropdownHead";
import DropdownLink from "./General/Dropdown/DropdownComponents/DropdownLink";
import ExpenseManagementNg from "../assets/svg/Landing/expense-management.png";
import GrowthNg from "../assets/svg/Landing/growth.png";
import {IRootState} from "../redux/rootReducer";
import {LencoCountriesOptions} from "../modules/Landing/CompanyBase/Services/CompanyBase.constant";
import {ReactComponent as LencoLogo} from "../assets/images/LencoLogo.svg";
import OnlineCheckout from "../assets/svg/Landing/OnlineCheckout.png";
import Parsers from "../utils/parsers";
import Payout from "../assets/svg/Landing/Payout.png";
import payoutDefault from "../assets/images/payoutDefault.png";
import expenseManagementDefault from "../assets/images/expenseManagementDefault.png";
import billPaymentDefault from "../assets/images/billPaymentDefault.png";
import PayoutNg from "../assets/svg/Landing/payout-ng.png";
import PhysicalAndVirtualCardsNg from "../assets/svg/Landing/physical-virtual-card.png";
import {SignUpCountries} from "../modules/Landing/Signup/Services/signup.constant";
import Slider from "react-slick";
import Terminal from "../assets/svg/Landing/Terminal.png";
import {setLandingCountry} from "../redux/landing/landingSlice";

interface LayoutProps {
	children: React.ReactNode;
	showHeaderSignUp?: boolean;
	showHeaderLogin?: boolean;
	inviteURL?: string;
	showNothing?: boolean;
}

function Layout({children, showNothing = false, showHeaderLogin = true, inviteURL = ""}: LayoutProps): JSX.Element {
	const settings = {
		infinite: true,
		slidesToShow: 1,
		dots: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToScroll: 1,
		speed: 500,
		pauseOnHover: true,
	};

	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const currentCountry = useSelector((state: IRootState) => state.landing.country);

	const isNigeria = currentCountry === SignUpCountries.NIGERIA;
	const isZambia = currentCountry === SignUpCountries.ZAMBIA;
	const queryParams = new URLSearchParams(location.search);
	const countryParam = queryParams.get("country");
	const activeCountry = LencoCountriesOptions.find((_country) => _country.value === currentCountry);
	const selectedCountry: SignUpCountries | undefined = countryParam ? Parsers.nullableEnum(countryParam, SignUpCountries) || undefined : undefined;

	const [isShowLogin, setIsShowLogin] = useState<boolean>(false);
	const [isCountryNotSelectable, setIsCountryNotSelectable] = useState<boolean>(false);

	/*useEffect(() => {
		window.history.replaceState({}, "Login", "/login");
	}, []);*/

	useEffect(() => {
		setIsShowLogin(
			location.pathname.includes("/signup") ||
				location.pathname.includes("/forgot-password") ||
				location.pathname.includes("/signup/business-type") ||
				location.pathname.includes("/signup/account") ||
				location.pathname.includes("/wait-list") ||
				location.pathname.includes("/director") ||
				location.pathname.includes("/referee")
		);
		setIsCountryNotSelectable(location.pathname.includes("/signup"));
		if (selectedCountry) {
			dispatch(setLandingCountry(selectedCountry));
		}
	}, [location, selectedCountry]);

	return (
		<>
			<div
				className="layout-section grid h-screen w-full grid-cols-1 overflow-x-hidden bg-white lg:grid-cols-12 lg:bg-blue-backdrop"
				tabIndex={-1}
			>
				<div className="relative col-span-7 m-0 flex h-full w-full  flex-col bg-white p-0" tabIndex={-1}>
					<div className="flex items-center justify-between px-4 pt-6 2xs:px-10">
						<div onClick={() => history.push("/login")}>
							<LencoLogo className="lg:w-full" />
						</div>
						{location.pathname !== "/signup" && location.pathname.includes("/signup") && (
							<div>
								<DropdownHead
									placeholder={
										<>
											<div className="flex flex-row items-center justify-start space-x-4">
												<img
													src={activeCountry?.icon}
													alt={activeCountry?.countryName}
													className="rounded-2xl border-2"
													width="28px"
													height="28px"
												/>
											</div>
										</>
									}
									isDisabled={isCountryNotSelectable}
									size="sm"
									placement="right"
									clickAndClose
									noOutlineBorder={isCountryNotSelectable}
									outline
									noHoverBg
									noTextHover
									noArrow={isCountryNotSelectable}
								>
									{LencoCountriesOptions.map((_country, index) => {
										return (
											<DropdownLink
												key={index}
												onClick={() => {
													dispatch(setLandingCountry(_country.value));
												}}
											>
												<p className="px-4 text-sm capitalize">{_country.countryName}</p>
											</DropdownLink>
										);
									})}
								</DropdownHead>
							</div>
						)}
					</div>
					<main className="relative flex h-full w-full max-w-6xl flex-col items-center justify-center overflow-y-auto">
						{children}
						<div className="py-8">
							{!showNothing && isShowLogin && showHeaderLogin && location.pathname !== "/" && (
								<p className="flex flex-row space-x-2 pt-2 text-sm font-normal text-black-tertiary">
									<span className="-mr-1 flex"> Already have an account?</span>
									<Link to={inviteURL || "/login"}>
										<span className=" whitespace-nowrap text-blue">Log In</span>
									</Link>
								</p>
							)}
							{!showNothing && !isShowLogin && location.pathname !== "/" && (
								<div>
									<p className="flex flex-row space-x-2 pt-2 text-sm font-normal text-black-tertiary">
										<span className="-mr-1"> Don&apos;t have a Lenco account?</span>
										<Link to={inviteURL || "/signup"}>
											<span className="whitespace-nowrap text-blue">Sign Up</span>
										</Link>
									</p>
								</div>
							)}
						</div>
					</main>
				</div>

				<div
					className="LandingSlider invisible col-span-5 flex h-0 flex-row justify-center overflow-hidden bg-white lg:visible lg:h-full lg:flex-col lg:bg-blue-senary"
					tabIndex={-1}
				>
					{isNigeria ? (
						<Slider {...settings} tabIndex={-1}>
							{/*<div className="item" tabIndex={-1}>
								<img src={BusinessCurrentAccountNg} alt="Business Current Account" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Business Current Account</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Real bank account for fast growing businesses. These account accept all forms of deposits.
									</p>
								</div>
							</div>*/}
							<div className="item" tabIndex={-1}>
								<img src={PayoutNg} alt="Payout" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Payout</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Make up to 5000 payments instantly with no effort. Upload a file to make bulk payouts from the dashboard or
										integrate our API to automate instant payouts,
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={ExpenseManagementNg} alt="Expense Management" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Expense Management</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Experience the power of automated reconciliation with transaction categorization, recipient grouping and post
										transaction comments.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={BillPaymentNg} alt="Bill Payment" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Bill Payment</h1>
									<div className="pt-6">
										<p className="text-center text-sm font-normal text-black-secondary">
											Your business airtime and utility bill in one place.
										</p>
										<p className="text-center text-sm font-normal text-black-secondary">Built for your team in mind.</p>
									</div>
								</div>
							</div>
							{/*<div className="item" tabIndex={-1}>
								<img src={GrowthNg} alt="Growth" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Growth</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Best growth capital available for your business. Opt-in to see your growth capital limit. No request. Money is
										in your account already.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={PhysicalAndVirtualCardsNg} alt="Physical and Virtual cards" className="m-auto w-full max-w-sm" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Physical and Virtual Cards</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Issue cards for your team to better track spending and manage your business. All our cards come with custom
										card management and access control.
									</p>
								</div>
							</div>*/}
						</Slider>
					) : isZambia ? (
						<Slider {...settings} tabIndex={-1}>
							<div className="item" tabIndex={-1}>
								<img src={BusinessTillAccount} alt="Business Till Account" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Business Till Account</h1>
									<p className="pt-6 text-sm font-normal text-black-secondary">
										Manage your business operations with Till accounts. Create unlimited tills to better organize your business
										operations.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={OnlineCheckout} alt="Business Till Account" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Online Checkout</h1>
									<p className="pt-6 text-sm font-normal text-black-secondary">
										Grow your sales, Accept payments directly from your website with a smooth & customizable checkout experience
										at low fees and great flexibility.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={expenseManagementDefault} alt="Expense Management" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Expense Management</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Experience the power of automated reconciliation with transaction categorization, recipient grouping and post
										transaction comments.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={AcceptPayments} alt="Business Till Account" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Accept Payments</h1>
									<p className="pt-6 text-sm font-normal text-black-secondary">
										Connect to our API to automate the collection of payment through Mobile Money and Cards (Local &
										International) from your customers.
									</p>
								</div>
							</div>

							<div className="item" tabIndex={-1}>
								<img src={Payout} alt="Business Till Account" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Payout</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Send money instantly to mobile money wallet or bank accounts automatically via APIs or manually from your
										Lenco dashboard.
									</p>
								</div>
							</div>

							{/*<div className="item" tabIndex={-1}>
								<img src={Terminal} alt="Business Till Account" className="m-auto mb-10 w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<div className="flex flex-col items-center justify-center space-y-2">
										<h1 className="text-2xl font-bold text-black">Terminal</h1>
										<div className="rounded-lg bg-warning-backdrop p-1.5 text-xs text-warning">Coming soon</div>
									</div>
									<p className="pt-6 text-sm font-normal text-black-secondary">
										Collect card and Mobile money in-person payments at your physical outlet with Instant settlement.
									</p>
								</div>
							</div>*/}
						</Slider>
					) : (
						<Slider {...settings} tabIndex={-1}>
							<div className="item" tabIndex={-1}>
								<img src={payoutDefault} alt="Payout" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Payout</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Make up to 5000 payments instantly with no effort. Upload a file to make bulk payouts from the dashboard or
										integrate our API to automate instant payouts,
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={expenseManagementDefault} alt="Expense Management" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Expense Management</h1>
									<p className="pt-6 text-center text-sm font-normal text-black-secondary">
										Experience the power of automated reconciliation with transaction categorization, recipient grouping and post
										transaction comments.
									</p>
								</div>
							</div>
							<div className="item" tabIndex={-1}>
								<img src={billPaymentDefault} alt="Bill Payment" className="m-auto w-full max-w-26" />
								<div className="mx-auto my-6 max-w-md text-center">
									<h1 className="text-2xl font-bold text-black">Bill Payment</h1>
									<div className="pt-6">
										<p className="text-center text-sm font-normal text-black-secondary">
											Your business airtime and utility bill in one place.
										</p>
										<p className="text-center text-sm font-normal text-black-secondary">Built for your team in mind.</p>
									</div>
								</div>
							</div>
						</Slider>
					)}
				</div>
			</div>
		</>
	);
}

export default Layout;
