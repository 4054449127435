import "yup-phone";

import {IconType, ToastType} from "../../../../../../helpers/AppConstants";
import React, {useCallback, useEffect, useState} from "react";
import {resetAllSendMoneyData, setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import ActivateAccount from "../../../../Accounts/Components/Modals/ActivateAccountModal";
import ActivateAccountList from "../../../../Transactions/Components/Modal/ActivateAccountList";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {ReactComponent as ChevronRightIcon} from "../../../../../../assets/images/chevronRight.svg";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {ReactComponent as GreenCheck} from "../../../../../../assets/images/bluecheck.svg";
import {IRootState} from "../../../../../../redux/rootReducer";
import Icon from "../../../../../../components/General/Icon/Icon";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import {ReactComponent as OrangeCheck} from "../../../../../../assets/svg/General/Approval/pendingApprovalIcon.svg";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {ReactComponent as RedCheck} from "../../../../../../assets/images/RedCheck.svg";
import {Routes} from "../../../../../../routes/routes.constants";
import SendableDropdown from "../../../../../../components/General/Dropdown/SendableDropdown/SendableDropdown";
import TagTransactionRecipient from "../../../../Transactions/Components/Modal/TagTransactionRecipient";
import {TailSpin} from "react-loader-spinner";
import Transaction from "../../../../../../models/transaction";
import TransactionCategory from "../../../../../../models/transactionCategory";
import UserAccount from "../../../../../../models/userAccount";
import formatNumber from "../../../../../../utils/formatNumber";
import {resetAllTransferBetweenData} from "../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {showTransactionDetails} from "../../../../../../redux/transaction/slice/transactionSlice";
import useCheckStatus from "../../../../../../hooks/useCheckStatus";
import {useHistory} from "react-router-dom";
import useTransactionDetailsCard from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCard";
import useTransactionDetailsCategory from "../../../../Transactions/Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsCategory";

function ProcessingPayment(): JSX.Element {
	const history = useHistory();
	const dispatch = useDispatch();
	const fundCardResponse = useSelector((state: IRootState) => state.fundCard.fundCardResponse);
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
	const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
	const transaction = useSelector<IRootState, Transaction | undefined>((state) => {
		return state.transaction.transactions.get(fundCardResponse?.transactionId || "");
	});

	const {showTagRecipientModal, setShowTagRecipientModal} = useTransactionDetailsCard();

	const {
		isSubmitting: isCategorySubmitting,
		transactionCategories: categoriesOptions,
		handleSetTransactionCategory,
		handleSetNewTransactionCategory,
	} = useTransactionDetailsCategory();

	useCheckStatus(transaction as Transaction);

	const [showActiveAccountModal, setShowActiveAccountModal] = useState(false);
	const [transactionCategory, setTransactionCategory] = useState("");
	const [showActiveAccountListModal, setShowActiveAccountListModal] = useState(false);
	const [accountToActivate, setAccountToActivate] = useState<UserAccount | null>(null);
	const [accountsToActivate, setAccountsToActivate] = useState<(UserAccount | undefined)[]>();

	useEffect(() => {
		if (!fundCardResponse) return;
		setAccountsToActivate(() => fundCardResponse.accountsToActivate.map((el) => accounts?.find((it) => it.id === el)));
	}, [fundCardResponse]);

	useEffect(() => {
		if (!accountsToActivate || accountsToActivate.length < 1 || showActiveAccountModal) return;
		setShowActiveAccountListModal(true);
	}, [accountsToActivate]);

	const handleActivatedAccount = useCallback((e: UserAccount) => {
		setAccountsToActivate((prev) => prev?.filter((el) => el?.id !== e.id));
		setShowActiveAccountListModal(false);
		setShowActiveAccountModal(false);
	}, []);

	const handleChangeTransactionCategory = useCallback((e) => {
		setTransactionCategory(e);
	}, []);

	const handleShowTransactionDetail = useCallback(() => {
		dispatch(showTransactionDetails(transaction?.id || ""));
	}, [dispatch]);

	return (
		<>
			{transaction ? (
				<>
					<ActivateAccount
						active={showActiveAccountModal}
						data={accountToActivate as UserAccount}
						toggler={() => setShowActiveAccountModal(false)}
						onCompleteFunc={handleActivatedAccount}
					/>

					<ActivateAccountList
						ShowAboutModal={showActiveAccountListModal}
						onClick={(e: UserAccount) => {
							setAccountToActivate(e);
							setShowActiveAccountModal(true);
							setShowActiveAccountListModal(false);
						}}
						accountsToActivate={accountsToActivate}
						SetShowAboutModal={() => setShowActiveAccountListModal(false)}
						isSingleAccount
					/>

					<TagTransactionRecipient
						showModal={showTagRecipientModal}
						toggler={() => setShowTagRecipientModal(false)}
						transaction={transaction}
					/>

					<div className="flex h-full w-full flex-col items-center justify-center">
						<div className="flex h-full w-full max-w-sm flex-col items-center justify-start">
							<div className={`flex w-full flex-col items-center justify-start pt-8 ${transaction.isProcessing ? "min-h-52" : ""}`}>
								<div>
									{transaction.isProcessing && (
										<div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-quin">
											<TailSpin color="#4756D1" height={20} width={20} />
										</div>
									)}
									{transaction.isPendingApproval && <OrangeCheck />}
									{transaction.isSuccess && <GreenCheck />}
									{transaction.isFailed && <RedCheck />}{" "}
								</div>
								<span className="py-4 text-center text-xl font-normal">
									{transaction.isProcessing && "Card is being funded..."}
									{transaction.isPendingApproval && "Card Funding has been sent for Approval"}
									{transaction.isSuccess && "Card Funded"}
									{transaction.isFailed && "Card funding failed"}{" "}
								</span>
								{!transaction.isFailed && (
									<>
										<div className="w-full max-w-xs pb-8 text-center">
											<span className=" text-base font-normal text-black-secondary">
												{transaction.isProcessing && "You are funding your Business Prepaid card with "}
												{transaction.isPendingApproval && "You have initiated a funding your Business Prepaid card with "}
												{transaction.isSuccess && "Your Business Prepaid card has been funded with "}{" "}
												<span>
													<CurrencyCode />
													{formatNumber(transaction.amount)}
												</span>{" "}
											</span>
										</div>
										{accountsToActivate && accountsToActivate.length > 0 && (
											<div className="cursor-pointer pb-8" onClick={() => setShowActiveAccountListModal(true)}>
												<MessageToasts
													toastMessage={
														(
															<div className="flex items-center justify-between">
																{" "}
																<span>Active account for payout</span>{" "}
																<div className="ml-4">
																	<ChevronRightIcon className="stroke-current text-info" />
																</div>
															</div>
														) as unknown as Element
													}
													toastType={ToastType.INFORMATION}
												/>
											</div>
										)}
									</>
								)}
								{transaction.isFailed && (
									<>
										<div className="w-full max-w-xs pb-8 text-center">
											<span className=" text-base font-normal text-black-secondary">Please try again </span>
										</div>
									</>
								)}
							</div>
							{transaction.isFailed && (
								<>
									<div className="flex flex-row items-center justify-center space-x-6">
										<div
											className="flex cursor-pointer flex-col items-center justify-center space-y-2"
											onClick={() => handleShowTransactionDetail()}
										>
											<div>
												<Icon icon={IconType.TRANSACTION_DETAILS} />
											</div>
											<div className="text-xs text-black-tertiary">Transaction Details</div>
										</div>
									</div>
								</>
							)}
							{!transaction.isFailed && !transaction.isProcessing && (
								<div className="w-full max-w-sm pb-8 lg:w-full  ">
									{categoriesOptions && (
										<SendableDropdown
											data={categoriesOptions}
											placeholder={`Select or Create a Category`}
											inputValue={transaction.category?.name || ""}
											value={transactionCategory}
											changeValue={handleChangeTransactionCategory}
											isLoading={isCategorySubmitting}
											clickAndClose
											createFunc={(name) => handleSetNewTransactionCategory(transaction, name)}
											cancelFunc={() => handleSetTransactionCategory(transaction, "")}
											onClickFunc={(option: TransactionCategory) => {
												setTransactionCategory(option.name);
												void handleSetTransactionCategory(transaction, option.id);
											}}
										/>
									)}
								</div>
							)}

							{!transaction.isProcessing && !transaction.isFailed && (
								<>
									<div className="flex flex-row items-center justify-center space-x-6">
										{/* {!transaction.isPendingApproval && (
											<div
												className="flex flex-col justify-center items-center space-y-2 cursor-pointer"
												onClick={() => handleDownloadLencoReceipt(transaction)}
											>
												<div>
													<Icon icon={IconType.DOWNLOAD} isLoading={isDownloading} />
												</div>
												<div className="text-xs text-black-tertiary">Download</div>
											</div>
										)} */}

										<div
											className="flex cursor-pointer flex-col items-center justify-center space-y-2"
											onClick={() => handleShowTransactionDetail()}
										>
											<div>
												<Icon icon={IconType.TRANSACTION_DETAILS} />
											</div>
											<div className="text-xs text-black-tertiary">Transaction Details</div>
										</div>

										{!transaction.isPendingApproval && transaction?.destination?.customerAccount && (
											<div
												className="flex cursor-pointer flex-col items-center justify-center space-y-2"
												onClick={() => setShowTagRecipientModal(true)}
											>
												<div>
													<Icon icon={IconType.SHARE} />
												</div>
												<div className="text-xs text-black-tertiary">Share Receipt</div>
											</div>
										)}
									</div>
								</>
							)}

							<div
								className={
									`flex w-full flex-row items-center justify-center space-x-4 pb-10 ` +
									`${!transaction.isProcessing ? "pt-8 " : ""}`
								}
							>
								<ButtonComp
									type="button"
									ripple="light"
									buttonType="secondary"
									color="grey"
									func={() => {
										dispatch(resetAllSendMoneyData());
										dispatch(resetAllTransferBetweenData());
										dispatch(setPaymentStage(PaymentStageType.INITIAL));
									}}
								>
									Make another payment
								</ButtonComp>
								{transaction.isFailed && (
									<div>
										<ButtonComp
											type="submit"
											color="blue"
											ripple="light"
											buttonType="primary"
											func={() => {
												if (pageFrom && payTo) {
													history.push({
														pathname: `/${pageFrom}`,
														state: {
															cardId: payTo,
														},
													});
												}
												dispatch(resetAllSendMoneyData());
												dispatch(resetAllTransferBetweenData());
												dispatch(setPaymentStage(PaymentStageType.INITIAL));
											}}
										>
											Done
										</ButtonComp>
									</div>
								)}
								{!transaction.isFailed && (
									<>
										<div>
											<ButtonComp
												type="submit"
												color="blue"
												ripple="light"
												buttonType="primary"
												func={() => {
													if (pageFrom && payTo) {
														history.push({
															pathname: `/${pageFrom}`,
															state: {
																cardId: payTo,
															},
														});
													} else {
														history.push({
															pathname: Routes.DASHBOARD,
														});
													}
													dispatch(resetAllSendMoneyData());
													dispatch(resetAllTransferBetweenData());
												}}
											>
												Done
											</ButtonComp>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="flex h-full w-full items-center justify-center pb-10">
					<TailSpin color="#5466F9" height={25} width={25} />
				</div>
			)}
		</>
	);
}

export default ProcessingPayment;
