export const Routes = {
	ACCOUNT_OPENING: Object.freeze({
		STATUS: "/application",
		SET_REGISTRATION_STATUS: "/application/registration-status",
		REVIEW: "/application/review",
		DONE: "/application/done",

		REGISTERED: Object.freeze({
			COMPANY: Object.freeze({
				DETAILS: "/application/company/details",
				INFORMATION: "/application/company/information",
				CONTACT: "/application/company/contact",
			}),
			SIGNATORY: Object.freeze({
				BVN: "/application/director/bvn",
				DETAILS: "/application/director",
				PERSONNEL: "/application/director/personnel",
				// SIGNATURE: "/application/director/signature",
				ID_NUMBER: "/application/director/id-number",
				PHOTOGRAPH: "/application/director/photograph",
				NEXT_OF_KIN: "/application/director/next-of-kin",
				MEANS_OF_ID: "/application/director/means-of-id",
				KEY_CONTACT: "/application/director/key-contact",
				PERSONAL_DETAILS: "/application/director/personal-details",
			}),
			/*REFEREE: Object.freeze({
				DETAILS: "/application/referees/choice",
				INVITE: "/application/referees/invite",
			}),*/
			DOCUMENT: "/application/documents",
			// AGREEMENT: "/application/agreements",
		}),

		UNREGISTERED: Object.freeze({
			BUSINESS: Object.freeze({
				DETAILS: "/application/business/details",
				DOCUMENT: "/application/document/upload",
			}),
			PERSONAL: Object.freeze({
				BVN: "/application/personal/bvn",
				DETAILS: "/application/personal/details",
			}),
			IDENTITY: Object.freeze({
				ID: "/application/identity/id",
				PHOTOGRAPH: "/application/identity/photograph",
			}),
		}),
	}),
	EXTERNAL_DIRECTOR: Object.freeze({
		NEXT_OF_KIN: "/next-of-kin",
		MEANS_OF_ID: "/means-of-id",
		REVIEW: "/review",
		DONE: "/done",
		PERSONAL_DETAILS: Object.freeze({
			BVN: "/personal-details/bvn",
			DETAILS: "/personal-details/details",
		}),
	}),
	LENCO_PAY: Object.freeze({
		APIS: "/lenco-pay/api-access",
		CONFIGURATION: "/lenco-pay/configuration",
	}),

	// todo -> remove /dashboard from these
	DASHBOARD: "/",
};
