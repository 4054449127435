import {DirectorPageType, DirectorPages, DirectorRoutes} from "../../service/director.constants";
import React, {ReactNode} from "react";

import ApplicationSectionCard from "../../../../../Dashboard/Application/components/cards/applicationSectionCard";
import BusinessDetailsIcon from "../../../../../../assets/svg/Application/UnregBusiness/BusinessDetails.svg";
import CompletedIcon from "../../../../../../assets/svg/Application/Completed.svg";
import {DirectorPage} from "../../service/directors.types";
import {IRootState} from "../../../../../../redux/rootReducer";
import InviteRefereesIcon from "../../../../../../assets/svg/Application/RegisteredBusiness/invite-referees.svg";
import OtherDirector from "../../../../../../models/account-opening/otherDirector";
import SignatoriesIcon from "../../../../../../assets/svg/Application/RegisteredBusiness/signatories.svg";
import Support from "../../../../../Dashboard/Application/components/cards/supportCard";
import Tags from "../../../../../Dashboard/Application/components/tags/ApplicationStatusTag";
import {ToastType} from "../../../../../../helpers/AppConstants";
import titleCase from "../../../../../../hooks/titleCase";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

function getIcon(page: DirectorPage): ReactNode {
	switch (page.value) {
		case DirectorPageType.PERSONAL:
			return <img src={BusinessDetailsIcon as string} alt="icon to depict a company" />;
		case DirectorPageType.NEXT_OF_KIN:
			return <img src={SignatoriesIcon as string} alt="icon to depict directors" />;
		case DirectorPageType.MEANS_OF_ID:
			return <img src={InviteRefereesIcon as string} alt="icon to depict referees" />;
		default:
			return <></>;
	}
}

function getPreviewIcon(page: DirectorPage, progress: OtherDirector): ReactNode {
	switch (page.value) {
		case DirectorPageType.PERSONAL:
			if (isCompleted(page, progress)) {
				return <img src={CompletedIcon as string} alt="icon to depict a completed section" />;
			} else {
				return getIcon(page);
			}
		case DirectorPageType.NEXT_OF_KIN:
			if (isCompleted(page, progress)) {
				return <img src={CompletedIcon as string} alt="icon to depict a completed section" />;
			} else {
				return getIcon(page);
			}
		case DirectorPageType.MEANS_OF_ID:
			if (isCompleted(page, progress)) {
				return <img src={CompletedIcon as string} alt="icon to depict a completed section" />;
			} else {
				return getIcon(page);
			}
		default:
			return <></>;
	}
}

function isCompleted(page: DirectorPage, progress: OtherDirector): boolean {
	switch (page.value) {
		case DirectorPageType.PERSONAL:
			return progress.isCompletedBio();
		case DirectorPageType.NEXT_OF_KIN:
			return progress.isCompletedNextOfKin();
		case DirectorPageType.MEANS_OF_ID:
			return progress.isCompletedIdentification();

		default:
			return false;
	}
}
function getSections(
	page: DirectorPage[],
	progress: OtherDirector | null
): {todoList: DirectorPage[]; underReviewList: DirectorPage[]; preview: DirectorPage[]} {
	const preview: DirectorPage[] = [];
	const todoList: DirectorPage[] = [];
	const underReviewList: DirectorPage[] = [];

	if (progress) {
		!progress.isCompletedBio()
			? todoList.push({...page.sort()[0], progress: progress.bioProgress()})
			: underReviewList.push({...page.sort()[0], progress: progress.bioProgress()});

		!progress.isCompletedNextOfKin()
			? todoList.push({...page.sort()[1], progress: progress.nextOfKinProgress()})
			: underReviewList.push({...page.sort()[1], progress: progress.nextOfKinProgress()});

		!progress.isCompletedIdentification()
			? todoList.push({...page.sort()[2], progress: progress.identificationProgress()})
			: underReviewList.push({...page.sort()[2], progress: progress.identificationProgress()});

		// preview.push({...page.sort()[0], progress: progress.businessCompletedProgress()});
	}
	return {todoList, underReviewList, preview};
}

function DirectorStatus(): JSX.Element {
	const history = useHistory();

	const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);
	const accountManager = useSelector((state: IRootState) => state.application.accountManager);
	const sections = getSections(DirectorPages, externalApplicationInit?.director || null);

	return (
		<>
			{externalApplicationInit && (
				<div className="w-full h-full flex flex-col justify-start xl:flex-row xl:justify-between px-4 2xs:px-10 lg:px-16 max-w-7xl mx-auto">
					<div className="flex flex-col h-full w-full xl:min-w-60%">
						<span className="text-3xl text-black text-left font-medium py-8">Director Details</span>
						<div className="pt-3 font-normal">
							<p className="font-medium text-lg capitalize text-black mb-2">
								Hello, {titleCase(externalApplicationInit.director.firstName || "")}
							</p>
							<p className="xl:max-w-3xl w-full text-black-secondary pb-8">
								{`${titleCase(externalApplicationInit.creator)} has listed you as a Director of `}
								{`${titleCase(
									externalApplicationInit.businessName
								)} for their account opening with lenco. Kindly complete your personal information below:`}
							</p>
						</div>

						<div className="flex flex-col justify-start items-start space-y-6 w-full xl:max-w-lg xl:min-w-max">
							{sections.todoList.length > 0 && (
								<div className="flex flex-col justify-start items-start space-y-4 w-full">
									<Tags text="To do" type={ToastType.INFORMATION} />
									{externalApplicationInit.director &&
										sections.todoList.sort().map((page) => (
											<ApplicationSectionCard
												key={page.value}
												icon={getIcon(page)}
												title={page.title}
												description={page.description}
												onClick={() => {
													history.push({
														pathname: `${url}${DirectorRoutes[page.value]}`,
														state: {
															from: history.location.pathname,
														},
													});
												}}
												progress={page.progress}
												isDisabled={false}
											/>
										))}
								</div>
							)}
							{sections.underReviewList.length > 0 && (
								<div className="flex flex-col justify-start items-start space-y-4 w-full">
									<Tags text="Under Review" type={ToastType.WARNING} />
									{externalApplicationInit.director &&
										sections.underReviewList.sort().map((page) => (
											<ApplicationSectionCard
												key={page.value}
												icon={getIcon(page)}
												title={page.title}
												description={page.description}
												onClick={() => {
													history.push({
														pathname: `${url}${DirectorRoutes[page.value]}`,
														state: {
															from: history.location.pathname,
														},
													});
												}}
												progress={page.progress}
												isDisabled={false}
											/>
										))}
								</div>
							)}
							{sections.preview.length > 0 && (
								<div className="flex flex-col justify-start items-start space-y-4 w-full">
									<Tags text="Preview" type={ToastType.SUCCESS_TOAST} />
									{externalApplicationInit.director &&
										sections.preview.sort().map((page) => (
											<ApplicationSectionCard
												key={page.value}
												icon={
													externalApplicationInit.director ? getPreviewIcon(page, externalApplicationInit.director) : <></>
												}
												title={page.title}
												description={page.description}
												onClick={() => {
													history.push({
														pathname: `${url}${DirectorRoutes[page.value]}`,
														state: {
															from: history.location.pathname,
														},
													});
												}}
												progress={page.progress}
												isDisabled={false}
											/>
										))}
								</div>
							)}
						</div>
					</div>
					<div className="mx-auto w-max">
						<Support
							email={accountManager?.email}
							name={accountManager?.name}
							phone={accountManager?.phone}
							photo={accountManager?.photo}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default DirectorStatus;
