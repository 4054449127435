import BusinessDetailsMin from "../../../../../models/account-opening/businessDetailsMin";

export type UpdateNigeriaBusinessTypeRequest = {
	isRegistered: boolean;
};

export type UpdateNigeriaBusinessTypeResponse = {
	applicationMin: BusinessDetailsMin;
};
export type NigeriaBusinessAccountManagerResponse = {
	name: string;
	photo: string;
	email: string;
	phone: string;
};
export type UpdateNigeriaBusinessAccountTypeRequest = {
	requestedProAccount: boolean;
};

export type UpdateNigeriaBusinessAccountTypeResponse = {
	applicationMin: BusinessDetailsMin;
};

//Registered enums
export enum NigeriaRegisteredCompanyStages {
	BUSINESS_DETAILS = "details",
	BUSINESS_INFORMATION = "information",
	BUSINESS_CONTACT = "contact",
}
export enum NigeriaRegisteredSignatoryStages {
	SIGNATORY_BVN = "bvn",
	SIGNATORY_DETAILS = "signatories",
	SIGNATORY_PERSONNEL = "personnel",
	SIGNATORY_SIGNATURE = "signature",
	SIGNATORY_PHOTOGRAPH = "photograph",
	SIGNATORY_NEXT_OF_KIN = "next-of-kin",
	SIGNATORY_MEANS_OF_ID = "means-of-id",
	SIGNATORY_KEY_CONTACT = "key-contact",
	SIGNATORY_PERSONAL_DETAILS = "personal-details",
}
export enum NigeriaRegisteredDocumentStages {
	DOCUMENT_UPLOAD = "upload",
}
export enum NigeriaRegisteredAgreementStages {
	DOCUMENT_AGREEMENT = "agreement",
}
export enum NigeriaRegisteredRefereeStages {
	REFEREE_CHOICE = "choice",
	REFEREE_DETAILS = "invite",
}

export enum NigeriaRegisteredSections {
	COMPANY = "company",
	SIGNATORY = "director",
	// REFEREES = "referees",
	UPLOAD = "upload",
	DOCUMENT = "documents",
	SIGNATURE = "signature",
	// AGREEMENT = "agreements",
	REVIEW = "review",
	DONE = "done",
	REGISTRATION = "registration-status",
	PERSONAL = "personal",
}

//Unregistered enums
export enum NigeriaUnregisteredPersonalStages {
	BVN = "bvn",
	PERSONAL_DETAILS = "details",
}
export enum NigeriaUnregisteredBusinessStages {
	BUSINESS_DETAILS = "business-details",
}
export enum NigeriaUnregisteredMeansOfIDStages {
	ID = "id",
	PHOTOGRAPH = "photograph",
}
export enum NigeriaUnregisteredSections {
	REVIEW = "review",
	BUSINESS = "business",
	PERSONAL = "personal",
	IDENTITY = "identity",
}

//General
export enum NigerianReviewStages {
	REVIEW = "review",
}

//Nigeria Sections
export type NigeriaApplicationSectionType = NigeriaRegisteredSections | NigeriaUnregisteredSections;

//Nigeria Application Navigation
export type NigeriaRegisteredStagesType =
	| NigeriaRegisteredCompanyStages
	| NigeriaRegisteredSignatoryStages
	| NigeriaRegisteredDocumentStages
	| NigeriaRegisteredAgreementStages
	| NigeriaRegisteredRefereeStages
	| NigerianReviewStages;

//Nigeria Application Navigation
export type NigeriaUnregisteredStagesType =
	| NigeriaUnregisteredPersonalStages
	| NigeriaUnregisteredBusinessStages
	| NigeriaUnregisteredMeansOfIDStages
	| NigerianReviewStages;

//Nigeria Stages
export type NigeriaApplicationStagesType = NigeriaRegisteredStagesType | NigeriaUnregisteredStagesType;

//Nigeria Stages
export const AllNigeriaRegisteredStagesType = {
	...NigeriaRegisteredCompanyStages,
	...NigeriaRegisteredSignatoryStages,
	...NigeriaRegisteredDocumentStages,
	...NigeriaRegisteredAgreementStages,
	...NigeriaRegisteredRefereeStages,
	...NigerianReviewStages,
};
export type AllNigeriaRegisteredStagesType = typeof AllNigeriaRegisteredStagesType;

//Nigeria Stages
export const AllNigeriaUnregisteredStagesType = {
	...NigeriaUnregisteredPersonalStages,
	...NigeriaUnregisteredBusinessStages,
	...NigeriaUnregisteredMeansOfIDStages,
	...NigerianReviewStages,
};
export type AllNigeriaUnregisteredStagesType = typeof AllNigeriaUnregisteredStagesType;

//Unregistered Routes
export enum NigeriaUnregisteredRoutes {
	PERSONAL_DETAILS = "details",
	BUSINESS_DETAILS = "business-details",
	ID = "id",
	PHOTOGRAPH = "photograph",
	REVIEW = "review",
}

//Registered Routes
export enum NigeriaRegisteredRoutes {
	BUSINESS_DETAILS = "details",
	BUSINESS_INFORMATION = "information",
	BUSINESS_CONTACT = "contact",
	SIGNATORY_DETAILS = "signatories",
	SIGNATORY_PERSONNEL = "personnel",
	// SIGNATORY_SIGNATURE = "signature",
	SIGNATORY_PHOTOGRAPH = "photograph",
	SIGNATORY_NEXT_OF_KIN = "next-of-kin",
	SIGNATORY_MEANS_OF_ID = "means-of-id",
	// SIGNATORY_KEY_CONTACT = "key-contact",
	SIGNATORY_PERSONAL_DETAILS = "personal-details",
	DOCUMENT_UPLOAD = "upload",
	// DOCUMENT_AGREEMENT = "agreement",
	// REFEREE_CHOICE = "choice",
	// REFEREE_DETAILS = "invite",
	REVIEW = "review",
}
