import {PaymentStageType, PaymentType} from "../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import React, {useEffect} from "react";
import {
	resetAllSendMoneyData,
	setPageFrom,
	setPayFrom,
	setPayTo,
	setPaymentStage,
	setPaymentType,
	setTempTrue,
} from "../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import BulkTransfer from "../Components/SendMoney/BulkTransfer";
import FundCard from "../Components/SendMoney/FundCard";
import {IRootState} from "../../../../redux/rootReducer";
import {PageTitle} from "../../../../helpers/AppConstants";
import SendMoneyLayout from "../Components/Layouts/SendMoney";
import SingleTransfer from "../Components/SendMoney/SingleTransfer";
// import TransferBetweenAccounts from "../Components/SendMoney/TransferBetweenAccounts";
import TypeOfTransfer from "../Components/SendMoney/TypeOfTransfer";
import ZambiaSingleTransfer from "../Components/SendMoney/Zambia/SingleTransfer";
import {resetAllBulkTransferData} from "../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {resetAllSingleTransferData} from "../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import {resetAllTransferBetweenData} from "../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {useLocation} from "react-router-dom";

interface LocationState {
	typeOfTransfer: PaymentType;
	from: string;
	to: string;
	pageFrom?: string;
	temp?: boolean;
}

function SendMoney(): JSX.Element {
	const dispatch = useDispatch();
	const location = useLocation<LocationState>();

	document.title = PageTitle.PAYMENTS_PAGE;

	const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);
	const paymentType = useSelector((state: IRootState) => state.sendMoney.paymentType);
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);

	useEffect(() => {
		if (paymentStage === PaymentStageType.INITIAL) {
			window.history.replaceState({}, "Payments", `/payments/make`);
		}
		if (paymentStage === PaymentStageType.PAYMENT_DETAILS) {
			window.history.replaceState({}, "Payments", `/payments/make/${paymentType}`);
		}
		if (paymentStage === PaymentStageType.REVIEW_PAYMENT) {
			window.history.replaceState({}, "Payments", `/payments/make/${paymentType}/review`);
		}
		if (paymentStage === PaymentStageType.MAKE_PAYMENT) {
			window.history.replaceState({}, "Payments", `/payments/make/${paymentType}/authorize`);
		}
		if (paymentStage === PaymentStageType.PROCESS_PAYMENT) {
			window.history.replaceState({}, "Payments", `/payments/make/${paymentType}/completed`);
		}
	}, [paymentStage, paymentType]);

	useEffect(() => {
		if (!location || !location.state) return;
		const {typeOfTransfer, from, to, temp, pageFrom} = location.state;
		if (typeOfTransfer) {
			dispatch(setPaymentType(typeOfTransfer));
			dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
		}
		if (from) {
			if (typeOfTransfer === PaymentType.FUND_CARDS) {
				dispatch(setPaymentType(PaymentType.FUND_CARDS));
			} else if (typeOfTransfer === PaymentType.TRANSFER_BETWEEN_ACCOUNT) {
				dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
			} else {
				dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			}
			// dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
			dispatch(setPayFrom(location.state.from));
		}
		if (to) {
			dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
			dispatch(setPayTo(location.state.to));
			if (typeOfTransfer === PaymentType.FUND_CARDS) {
				dispatch(setPaymentType(PaymentType.FUND_CARDS));
			}
			if (typeOfTransfer !== PaymentType.FUND_CARDS) {
				dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			}
		}
		if (temp) {
			dispatch(setTempTrue());
		}
		if (pageFrom) {
			dispatch(setPageFrom(pageFrom));
		}
	}, [location]);

	useEffect(() => {
		return () => {
			dispatch(resetAllSendMoneyData());
			dispatch(resetAllSingleTransferData());
			dispatch(resetAllTransferBetweenData());
			dispatch(resetAllBulkTransferData());
		};
	}, []);

	return (
		<>
			<SendMoneyLayout>
				{paymentStage === PaymentStageType.INITIAL && <TypeOfTransfer />}
				{isNigeria && paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.SINGLE_TRANSFER && <SingleTransfer />}
				{/* {paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.TRANSFER_BETWEEN_ACCOUNT && <TransferBetweenAccounts />} */}
				{isNigeria && paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.BULK_TRANSFER && <BulkTransfer />}
				{isNigeria && paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.FUND_CARDS && <FundCard />}
				{isZambia && paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.SINGLE_TRANSFER && <ZambiaSingleTransfer />}
				{/* {isZambia && paymentStage !== PaymentStageType.INITIAL && paymentType === PaymentType.BULK_TRANSFER && <ZambiaBulkTransfer />} */}
			</SendMoneyLayout>
		</>
	);
}

export default SendMoney;
