import Transaction from "../../../../../../../models/transaction";
import {TransactionStatus} from "../../../../../../../models/transaction.constants";

export function getTransactionStatusData(transaction: Transaction): {text: string; bgClass: string; textClass: string} {
	switch (transaction.status) {
		case TransactionStatus.SUCCESS:
			return {text: "Success", bgClass: "bg-success-backdrop", textClass: "text-success"};
		case TransactionStatus.FAILED:
			return {text: "Failed", bgClass: "bg-error-backdrop", textClass: "text-error"};
		case TransactionStatus.PENDING_APPROVAL:
			return {text: "Pending Approval", bgClass: "bg-warning-backdrop", textClass: "text-warning"};
		case TransactionStatus.DECLINED:
			return {text: "Approval Declined", bgClass: "bg-error-backdrop", textClass: "text-error"};
		case TransactionStatus.CANCELLED:
			return {text: "Cancelled", bgClass: "bg-error-backdrop", textClass: "text-error"};
		case TransactionStatus.PROCESSING:
			return {
				text: transaction.isActivationPending ? "Pending - Account Activation" : "Processing",
				bgClass: "bg-warning-backdrop",
				textClass: "text-warning",
			};
	}
}
