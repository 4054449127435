import Currency from "../../models/currency";
import {IRootState} from "../../redux/rootReducer";
import React from "react";
import {sanitize} from "dompurify";
import {useSelector} from "react-redux";

interface CurrencyCodeProps {
	currency?: Currency | null | undefined;
}

function CurrencyCode({currency, ...props}: CurrencyCodeProps): JSX.Element {
	const currencyFallback = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts[0]?.bankAccount?.currency);

	return (
		<>
			{(currency || currencyFallback) && (
				<span {...props} dangerouslySetInnerHTML={{__html: sanitize(currency?.htmlCode || currencyFallback?.htmlCode || "")}} />
			)}
		</>
	);
}

export default CurrencyCode;
